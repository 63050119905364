import './Home.scss';

import Order from '../OrderComponent/Order'
import StoreBanner from '../StoreBannerComponent/StoreBanner'

function Home() {

  return (
    <div className="Home">
      <Order/>
      <div className="MainBanner">
        <div className="MainBannerText">
          <div>
            <p>
              Authentic
              <br/>
              Peruvian
              <br/>
              Food
            </p>
            <div
            className="BannerButton no_highlights"
            onClick={() => window.location.href='https://www.toasttab.com/sabor-a-peru/v3'}
            >
              Order Now
            </div>
          </div>
        </div>
        <div className="MainBannerImage"/>
      </div>
      <StoreBanner/>
      {/* <Menu buttonRef={buttonRef}/> */}
    </div>
  );
}

export default Home;
