import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import './App.scss';
import Home from './Components/HomeComponent/Home'

function App() {

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <Home/>
          </Route>
          <Redirect from="/*" to="/" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
