import { useState } from 'react'

import "./Order.scss"
import xIcon from "../../assets/xIcon.svg"


export default function Menu( props ) {
    const [Open, setOpen] = useState(false)


    // const OpenSideOrder = (e) => {
    //     setOpen(true)
    // }

    const CloseSideOrder = (e) => {
        setOpen(false)
    }


    return (
        <>
            <div className="OrderContainer">
                <div className="Order">
                    {/* <a className='OrderButton no_highlights' href="https://www.clover.com/online-ordering/sabor-a-peru">
                        Order Now
                    </a> */}
                    {/* <div className='OrderButton no_highlights' onClick={OpenSideOrder}>
                        Order Now
                    </div> */}
                </div>
            </div>
            <div className="Spacer"/>
            <div className={`SideOder ${Open ? "Open" : "Close"}`}>
                <div className="Background"/>
                <div className='Nav'>
                    <img className='xIcon' src={xIcon} onClick={CloseSideOrder} alt=''/>
                </div>
                <div className="Spacer"/>
                <div className='Main'>

                </div>
            </div>
        </>
    )
}