import React, {useContext, useState} from 'react';

const MenuItems = React.createContext()
const MenuItemsUpdate = React.createContext()

export function useMenuItems() {
  return useContext(MenuItems)
}

export function useMenuItemsUpdate() {
  return useContext(MenuItemsUpdate)
}

export function MenuItemsProvider({ children }) {
  const [menuItems, setMenuItems] = useState([])

  function menuItemsUpdate(newItems) {
    return setMenuItems(newItems)
  }

  return (
    <MenuItems.Provider value={menuItems}>
      <MenuItemsUpdate.Provider value={menuItemsUpdate}>
        { children }
      </MenuItemsUpdate.Provider>
    </MenuItems.Provider>
  )
}


